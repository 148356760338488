<template>
  <div>
    <div class="row">
      <div class="col-5">
        <div class="mb-3 d-flex justify-content-end">
          <el-button
              :size="size"
              @click="showAddForm()"
              type="success"
              v-if="!showForm"
          >
            <font-awesome-icon icon="plus" />
          </el-button>
        </div>
        <template v-if="showForm">
          <el-radio-group
              :size="size"
              @change="onLocalizationTypeChange"
              v-model="localizationType"
          >
            <el-radio-button
                label="customer"
                name="customer"
            >{{ $t('system.customer') }}
            </el-radio-button>
            <el-radio-button
                label="new"
                name="new"
            >{{ $t('system.new_localization') }}
            </el-radio-button>
          </el-radio-group>
          <el-form
              :size="size"
              class="dense-form mt-4"
          >
            <div class="row">
              <div class="col-6">
                <el-form-item
                    :label="$t('system.customer_location')"
                    :required="localizationType === 'customer'"
                >
                  <el-select
                      :disabled="localizationType !== 'customer'"
                      @change="setForm"
                      class="w-100"
                      filterable
                      v-model="form.customerLocalizationId"
                  >
                    <el-option
                        :key="location.id"
                        :label="generateAddressString(location)"
                        :value="location.id"
                        v-for="location in customersLocations"
                    />
                  </el-select>
                </el-form-item>
              </div>
              <div class="col-6">
                <el-form-item
                    :label="$t('system.state')"
                    required
                >
                  <el-select
                      :disabled="isFormDisabled"
                      class="w-100"
                      filterable
                      v-model="form.state"
                  >
                    <el-option
                        :key="state.id"
                        :label="$t(`system.${state.name.split(' ').join('_').toLowerCase()}`)"
                        :value="state.id"
                        v-for="state in statesList"
                    />
                  </el-select>
                </el-form-item>
              </div>
              <div class="col-12">
                <el-form-item
                    :label="$t('system.address')"
                    required
                >
                  <el-input
                      :disabled="isFormDisabled"
                      v-model="form.address"
                  />
                </el-form-item>
              </div>
              <div class="col-6">
                <el-form-item
                    :label="$t('system.city')"
                    required
                >
                  <el-input
                      :disabled="isFormDisabled"
                      v-model="form.city"
                  />
                </el-form-item>
              </div>
              <div class="col-6">
                <el-form-item
                    :label="$t('system.postal_code')"
                    required
                >
                  <el-input
                      :disabled="isFormDisabled"
                      v-model="form.postal_code"
                  />
                </el-form-item>
              </div>
              <div
                  class="col-12"
                  v-if="!isFormDisabled"
              >
                <el-button
                    :size="size"
                    @click="searchLocation"
                    class="w-100"
                    type="info"
                >
                  {{ $t('system.search_on_map') }}
                </el-button>
              </div>
              <div class="col-12">
                <el-form-item
                    :disabled="isFormDisabled"
                    :label="$t('system.note')"
                >
                  <el-input
                      type="textarea"
                      v-model="form.note"
                  />
                </el-form-item>
              </div>
            </div>
            <el-form-item class="d-flex justify-content-end">
              <el-button
                  :disabled="!canAddLocation"
                  :loading="saveLoading"
                  @click="addToList"
                  type="success"
              >
                {{ $t('system.add') }}
              </el-button>
              <el-button @click="closeForm">
                {{ $t('system.cancel') }}
              </el-button>
            </el-form-item>
          </el-form>
        </template>
        <div
            class="locations-list"
            v-else
            v-loading="fetchListLoading"
        >
          <LocalizationListItem
              :item="{...item, state: statesList.find(state => state.id === item.state)}"
              :key="item.id"
              :number="idx + 1"
              @addressClicked="showLocationOnMap(item, ...arguments)"
              @remove="removeLocation(item, ...arguments)"
              v-for="(item, idx) in localLocations"
          />
        </div>
      </div>
      <div
          class="col-7"
          style="height: 436px"
      >
        <l-map
            :center="form.coordinates && form.coordinates.length ? form.coordinates : center"
            :zoom="zoom"
            @click="onMapClicked"
            @update:center="setCenter"
        >
          <l-tile-layer url="https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=cSJ7m9qsgRjbKQInLyYn"></l-tile-layer>
          <l-marker
              v-for="location in localLocations"
              :key="location.id"
              :lat-lng="location.coordinates"
          >
            <l-popup :content="generateAddressString(location)" />
          </l-marker>
          <l-marker
              :lat-lng="form.coordinates"
              @update:latLng="onMarkerDragged"
              v-if="form.coordinates && form.coordinates.length"
          />
        </l-map>
      </div>
    </div>
  </div>
</template>

<script>
import {LMap, LMarker, LPopup, LTileLayer} from 'vue2-leaflet';
import {Icon} from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import LocalizationListItem from '@/components/WorkOrderComponents/Localizations/LocalizationListItem';
import {clone, mapObjToObj} from '@/helpers/functions';
import notification from '@/notification/notify';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const emptyForm = {
  address: '',
  city: '',
  state: '',
  note: '',
  postal_code: '',
  isMarkerDraggable: true,
  customerLocalizationId: null,
  coordinates: [],
};
export default {
  name: 'LocalizationsIndex',
  components: {
    LocalizationListItem,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  props: {
    orderId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    center: [35, -95],
    statesList: [],
    showForm: false,
    saveLoading: false,
    fetchListLoading: false,
    customersLocations: [],
    size: 'mini',
    form: clone(emptyForm),
    localizationType: 'customer',
    localLocations: [],
    zoom: 3,
  }),
  computed: {
    canAddLocation() {
      if (this.localizationType === 'customer') {
        return !!this.form.customerLocalizationId;
      }
      return true;
    },
    isFormDisabled() {
      return this.localizationType === 'customer';
    },
  },
  created() {
    this.fetchAddressesList();
    this.fetchStatesList();
  },
  methods: {
    setCenter(center) {
      this.center = center;
    },
    closeForm() {
      this.showForm = false;
      this.form = clone(emptyForm);
    },
    showLocationOnMap(location) {
      if (location.coordinates) {
        if (typeof location.coordinates === 'string') {
          this.form.coordinates = location.coordinates.split(';');
        } else {
          this.form.coordinates = [...location.coordinates];
        }
      } else {
        this.form.coordinates = [];
      }
      this.form.isMarkerDraggable = false;
    },
    async removeLocation(location) {
      try {
        this.fetchListLoading = true;
        await axios.delete(`workorders/location/${location.id}`);
        await this.fetchAddressesList();
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_remove_location'),
            'error');
      }
    },
    generateAddressString(item) {
      const addressFields = [
        item.address,
        item.city,
        item.state,
      ];
      return addressFields.filter(item => !!item).join(', ');
    },
    onMapClicked(point) {
      if (this.showForm && (!this.form.coordinates || !this.form.coordinates.length)) {
        this.onMarkerDragged(point.latlng);
      }
    },
    onMarkerDragged(coordinates) {
      this.form.coordinates = [coordinates.lat, coordinates.lng];
    },
    showAddForm() {
      this.showForm = true;
      this.form.isMarkerDraggable = true;
      this.form.coordinates = [];
      // this.$nextTick(
      //   setTimeout(() => {
      //     this.zoom = 4;
      //   }, 100),
      // );
    },
    async searchLocation() {
      try {
        let state = '';
        const stateCandidate = this.statesList.find(item => item.id === this.form.state);
        if (stateCandidate) {
          state = stateCandidate.name;
        }
        const results = await axios.get(`https://nominatim.openstreetmap.org/search?street=${this.form.address}&city=${this.form.city}&state=${state}&postalcode=${this.form.postal_code}&format=json`);
        if (results.data.length) {
          this.form.coordinates = [results.data[0].lat, results.data[0].lon];
          this.$nextTick(
              setTimeout(() => {
                this.zoom = 11;
              }, 500),
          );
        } else {
          notification.notify(
              this.$t('system.set_location_manually'),
              this.$t('system.can_not_find_location'), 'warning');
        }
      } catch {
        notification.notify(
            this.$t('system.set_location_manually'),
            this.$t('system.can_not_find_location'), 'warning');
      }
    },
    onLocalizationTypeChange() {
      this.customerLocalizationId = null;
      const existCoordinates = this.form.coordinates;
      this.form = clone(emptyForm);
      this.form.coordinates = existCoordinates;
    },
    async fetchStatesList() {
      try {
        const {data} = await axios.get('workorders/location/states_list');
        this.statesList = data;
      } catch (e) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load_states'),
            'error');
      }
    },
    async fetchAddressesList() {
      try {
        this.fetchListLoading = true;
        const {data} = await axios.get(`/workorders/location/${this.orderId}`);
        this.customersLocations = data.accountAddresses.map(item => item.base);
        if (data.orderLocations && Array.isArray(data.orderLocations)) {
          data.orderLocations.forEach((element) => {
            if (element.coordinates && element.coordinates[0] === '{') {
              const coordinates = JSON.parse(element.coordinates);
              element.coordinates = `${coordinates.lat};${coordinates.lng}`;
            }
          });
        }
        this.localLocations = data.orderLocations.map(item => ({
          ...item,
          coordinates: item.coordinates.split(';'),
        }));
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load_locations'),
            'error');
      } finally {
        this.fetchListLoading = false;
      }
    },
    setForm(id) {
      const candidate = this.customersLocations.find(item => item.id === id);
      if (candidate) {
        console.log(this.form, candidate);
        this.form = mapObjToObj(this.form, candidate);
      } else {
        this.form = clone(emptyForm);
      }
    },
    async addToList() {
      try {
        this.saveLoading = true;
        if (this.localizationType === 'customer') {
          await this.saveCustomerLocation(this.form.customerLocalizationId, this.form);
        } else {
          await this.saveNewLocation(this.form);
        }
        const coordinatesCopy = JSON.parse(JSON.stringify(this.form.coordinates));
        this.form = clone(emptyForm);
        this.form.coordinates = JSON.parse(JSON.stringify(coordinatesCopy));
        this.showForm = false;
      } catch (error) {

        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_save'),
            'error');
      } finally {
        this.saveLoading = false;
      }
      await this.fetchAddressesList();
    },

    saveCustomerLocation(addressId, {note}) {
      return axios.post(`workorders/location/copy_address/${this.orderId}/${addressId}`, {
        note,
      });
    },
    saveNewLocation({
                      address,
                      city,
                      state,
                      coordinates,
                      note,
                      postal_code,
                    }) {
      return axios.post(`workorders/location/add_address/${this.orderId}`, {
        address,
        city,
        state,
        note,
        postal_code,
        coordinates: coordinates.join(';'),
      });
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>
.locations-list {
  max-height: 400px;
  overflow: auto;
}
</style>
